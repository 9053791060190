export default function Footer() {
  return (
    <footer className="mt-32 px-6 h-[80px] bg-footer text-very-light-pinkish-blue dark:text-very-light-blue md:px-10" id="contacts">
      <div className="max-w-container mx-auto h-full flex justify-between items-center">
        <p className="text-[0.875rem] md:text-[1rem] font-roboto tracking-wide">Cleopatra Analytics</p>
        <ul className="flex gap-5">
          {
            // TODO: uncomment
            /* <li>
            <a
              href="https://t.me/CleopatraSupportBot"
              target="_blank"
              className="hover:text-white dark:hover:text-blue-hover transition-colors"
            >
              <i className="fa-solid fa-headset fa-lg"></i>
            </a>
          </li> */
          }
          <li>
            <a
              href="https://t.me/CleopatraAnalytics"
              target="_blank"
              className="hover:text-white dark:hover:text-blue-hover transition-colors"
            >
              <i className="fa-brands fa-telegram fa-lg"></i>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" className="hover:text-white dark:hover:text-blue-hover transition-colors">
              <i className="fa-solid fa-comments fa-lg"></i>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/cleoanalytics?s=11&t=6hwJzIaEtxqQxWYTrPp7-w"
              target="_blank"
              className="hover:text-white dark:hover:text-blue-hover transition-colors"
            >
              <i className="fa-brands fa-square-x-twitter fa-lg"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
