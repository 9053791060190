import { filters } from '../data/multyTable'
import { AnyObject, SelectType } from '../types/multy'

const BILLION = 1_000_000_000
const MILLION = 1_000_000
const THOUSAND = 1_000

type numberShorthand = '' | 'K' | 'M' | 'B'

export function getFormattedNumber(originalValue: number, decimalCount: number = 2) {
  let result: number = originalValue
  let shorthand: numberShorthand = ''

  if (originalValue >= BILLION || originalValue <= -BILLION) {
    shorthand = 'B'
    result /= BILLION
  } else if (originalValue >= MILLION || originalValue <= -MILLION) {
    shorthand = 'M'
    result /= MILLION
  } else if (originalValue >= THOUSAND || originalValue <= -THOUSAND) {
    shorthand = 'K'
    result /= THOUSAND
  }
  
  try{
    return result.toFixed(decimalCount) + shorthand
  } catch {
    return '-'
  }
}

export function getSeparatedNumber(value: number, decimalCount = 2) {
  return value ? value.toLocaleString('en-US', { minimumFractionDigits: decimalCount, maximumFractionDigits: decimalCount }).replace(/,/g, ' ') : '-'
}

export function getTooltipClasses(value: number) {
  return value >= THOUSAND || value <= -THOUSAND ? 'tooltip tooltip-center' : ''
}

export function getValueColor(value: number) {
  return value > 0 ? '#7EE353' : '#E85D75'
}

export function transformFilters(data: AnyObject, isDivided: boolean) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const currentFilter = filters.find(filter => filter.key === key)
      const result: AnyObject = {}

      if (value.start) {
        result.start = transformValue(value.start, currentFilter?.unit, isDivided)
      }

      if (value.end) {
        result.end = transformValue(value.end, currentFilter?.unit, isDivided)
      }

      return currentFilter?.type === SelectType.VALUES ? [key, result] : [key, value]
    })
  )
}

function transformValue(value: number, unit: string | undefined, isDivided: boolean) {
  if (unit === '%') {
    return isDivided ? value / 100 : value * 100
  }

  return value
}

export function processObject(obj: AnyObject) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      // Recursively process nested objects
      obj[key] = processObject(obj[key])
    } else if (obj[key] === '' || obj[key] === 0) {
      // Remove empty keys
      delete obj[key]
    } else if (!isNaN(parseFloat(obj[key]))) {
      // Convert string values to numbers
      obj[key] = parseFloat(obj[key])
    }
  }
  return obj
}
